<template>
   <div class="account-pages mt-5 mb-5">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-4">
               <div class="card bg-pattern">
                  <div class="card-body p-4">
                     <div class="text-center w-75 m-auto">
                        <p class="text-muted mb-4 mt-3">
                           Enter your email address and password to access admin
                           panel.
                        </p>
                     </div>

                     <form action="#">
                        <div class="mb-3">
                           <label for="emailaddress" class="form-label"
                              >Employee Email / ID</label
                           >
                           <input
                              class="form-control"
                              type="email"
                              id="emailaddress"
                              required=""
                              placeholder="Enter your email"
                              v-model="input.email"
                              :class="{
                                 'p-invalid':
                                    v$.input.email.$error ||
                                    errorFor('email_or_employee_id'),
                              }"
                           />
                           <v-errors
                              :serverErrors="errorFor('email_or_employee_id')"
                              :vuelidateErrors="{
                                 errors: v$.input.email.$errors,
                                 value: 'Email / Employee ID',
                              }"
                           ></v-errors>
                        </div>

                        <div class="mb-3">
                           <label for="password" class="form-label"
                              >Password</label
                           >
                           <div class="input-group input-group-merge">
                              <input
                                 type="password"
                                 id="password"
                                 class="form-control"
                                 placeholder="Enter your password"
                                 v-model="input.password"
                                 v-on:keyup.enter="submit"
                                 :class="{
                                    'p-invalid':
                                       v$.input.password.$error ||
                                       errorFor('password'),
                                 }"
                              />

                              <div
                                 class="input-group-text"
                                 data-password="false"
                              >
                                 <span class="password-eye"></span>
                              </div>
                           </div>
                           <v-errors
                              :serverErrors="errorFor('password')"
                              :vuelidateErrors="{
                                 errors: v$.input.password.$errors,
                                 value: 'Password',
                              }"
                           ></v-errors>
                        </div>

                        <div class="text-center d-grid">
                           <button
                              class="btn btn-primary"
                              type="button"
                              @click="submit"
                           >
                              Log In
                           </button>
                        </div>
                     </form>
                  </div>
                  <!-- end card-body -->
               </div>
               <!-- end card -->
            </div>
            <!-- end col -->
         </div>
         <!-- end row -->
      </div>
      <!-- end container -->
   </div>
</template>

<script>
import { mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
   setup() {
      return { v$: useVuelidate() };
   },
   mixins: [validationErrors],
   data() {
      return {
         input: {
            email: "",
            password: "",
         },
         token: "",
         isLoading: false,
      };
   },
   validations() {
      return {
         input: {
            email: { required },
            password: { required },
         },
      };
   },
   methods: {
      ...mapActions(["auth/login"]),
      async submit() {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;

         this.isLoading = true;
         this["auth/login"](this.input)
            .then(() => {
               this.$router.push({ name: "home" });
            })
            .catch((error) => {
               this.errors = error;
            });
         this.isLoading = false;
      },
   },
   mounted() {
      document.body.classList.add(
         "authentication-bg",
         "authentication-bg-pattern"
      );
   },
   unmounted() {
      document.body.classList.remove(
         "authentication-bg",
         "authentication-bg-pattern"
      );
   },
};
</script>